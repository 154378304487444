import React, { useState } from "react";
import { Navigate, Outlet, useOutletContext } from "react-router-dom";
import styled from "styled-components";
import { BiMenuAltLeft } from "react-icons/bi";
import Dashboardheader from "../../components/Dashboardheader";
import LNavbar from "../../components/LNavbar";
import WarningA from "../../components/WarningA";
import ModalA from "../../components/bits/ModalA";
import { getParams } from "../../helpers/otherHelpers";
import { NavLink } from "react-router-dom";
import { settings } from "../../utils/routes";
import { logoutUser, userData } from "../../helpers/authHelper";
import logoutImg from "../../assets/icons/logout_red.svg";
import notifImg from "../../assets/icons/d-nav-notif.svg";
import profImg from "../../assets/img/image 1.png";
import {
  useGetCoopGroupInvitesQuery,
  useGetTarGroupInvitesQuery,
} from "../../redux/services/saving-service";
import MNavbar from "../../components/MNavbar";
import gsap from "gsap";

type ContextType = { show: boolean; setShow: Function };
type ContextType2 = { showNav: boolean; setShowNav: Function };

type AuthWrapperProps = {
  show: boolean;
};

export function useShow() {
  return useOutletContext<ContextType>();
}

export function useShowNav() {
  return useOutletContext<ContextType2>();
}
function AuthIndex() {
  const [show, setShow] = useState<boolean>(false);
  const [showNav, setShowNav] = useState<boolean>(true);
  const [showMobileNav, setShowMobileNav] = useState<boolean>(false);
  const [logoutOpen, setLogoutOpen] = useState<boolean>(false);
  const [userData2, setUserData2] = useState<any>();
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   setTimeout(() => {
  //     logoutUser();
  //   }, 10 * 60 * 1000);
  // }, []);

  // if (!sessionStorage.getItem("token")) {
  //   window.location.pathname = "/";
  // }

  const events = [
    "load",
    "mousemove",
    "click",
    "scroll",
    "keypress",
    "keydown",
    "DOMMouseScroll",
    "mousewheel",
    "mousedown",
    "touchstart",
    "touchmove",
    "MSPointerDown",
    "MSPointerMove",
    "visibilitychange",
  ];

  let timerRef = React.useRef<any>(null);

  // this function sets the timer that open the logout modal after 4 minutes
  const handleLogoutTimer = () => {
    timerRef.current = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // opens the modal
      setLogoutOpen(true);
    }, 4 * 60 * 1000);
  };
  // const {
  //   data: coopData,
  //   isLoading: coopDataLoading,
  //   refetch: coopRefetch,
  // }: any = useGetCoopGroupInvitesQuery();
  // const {
  //   data: tarData,
  //   isLoading: tarDataLoading,
  //   refetch: tarRefetch,
  // }: any = useGetTarGroupInvitesQuery();
  // const notifData = coopData && tarData && [...coopData, ...tarData];
  // const notifNo: number = notifData?.length;

  // when component mounts, it adds an event listener to the window
  // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to opens the modal after 4 minutes of inactivity.
  // However, if none of the event is triggered within 4 minutes, that means app is inactive, the modal automatically opens.

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timerRef.current) clearTimeout(timerRef.current);
  };

  React.useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
    // eslint-disable-next-line
  }, []);

  const getUserData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/user`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/xml",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.json();
      setLoading(false);
      if (!response.ok) {
        console.log("error fetching user data");
        return;
      }
      setUserData2(data?.data);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getUserData();
  }, []);

  const Authenticated = () => {
    return (
      <>
        <AuthIndex.Wrapper show={show}>
          <div className="left" onClick={() => setShow(false)}>
            <div className="l-nav">
              <LNavbar setOpen={setShow} />
            </div>
          </div>
          <div className="right">
            {showNav && <Dashboardheader show={show} setShow={setShow} />}
            <Outlet context={{ show, setShow, showNav, setShowNav }} />
            {/* these contexts are available to every component under outlet- */}
          </div>
          <div className="mobileTopNav">
            <div className="menuOpen">
              <BiMenuAltLeft
                onClick={() => {
                  setShowMobileNav(true);
                }}
              />
            </div>
            <div className="m-nav">
              <NavLink to={settings} className="tnav-link">
                {loading === false ? (
                  <img
                    src={userData2?.user_profile?.avatar}
                    alt="user display icon"
                    className="nav-img"
                    style={{
                      borderRadius: "100%",
                    }}
                  />
                ) : (
                  <img
                    src={profImg}
                    alt="user display icon"
                    className="nav-img"
                    style={{
                      borderRadius: "100%",
                    }}
                  />
                )}
              </NavLink>

              <NavLink to="settings?tab=4" className="tnav-link-notif">
                <img
                  src={notifImg}
                  className="nav-img"
                  alt="notifications icon"
                />
                {/* {notifNo > 0 && <span className="notif-no">{notifNo}</span>} */}
              </NavLink>

              <NavLink
                to="#"
                onClick={() => setLogoutOpen(true)}
                className="tnav-link"
              >
                <img src={logoutImg} className="logout-img" alt="logout icon" />
                {/* <p className="mb-0">Logout</p> */}
              </NavLink>
            </div>
          </div>
        </AuthIndex.Wrapper>
        <ModalA isShown={logoutOpen} hide={() => {}}>
          <WarningA
            warningTitle={"Confirm Logout"}
            closeModal={() => {
              setLogoutOpen(false);
            }}
            confirm={() => logoutUser()}
            confirmText={"OK"}
          />
        </ModalA>
        {showMobileNav && (
          <MNavbar isShow={showMobileNav} setIsShow={setShowMobileNav} />
        )}
      </>
    );
  };

  return sessionStorage.getItem("token") ? (
    <Authenticated />
  ) : (
    <Navigate
      to={
        window.location.pathname !== "/user"
          ? "/login" + getParams(window.location)
          : `/login`
      }
      // to={
      //   window.location.pathname !== "/user"
      //     ? `/login?redirect=${window.location.pathname}${window.location.search}`
      //     : `/login`
      // }
      replace={true}
    />
  );
}

AuthIndex.Wrapper = styled.div`
  // width: 100vw;
  display: flex;
  position: relative;

  .mobileTopNav {
    position: absolute;
    top: 2rem;
    width: 100vw;
    max-width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem 0 0.7rem;
    .menuOpen {
      svg {
        font-size: 50px;
        color: rgb(5, 145, 87);
        cursor: pointer;
      }
    }

    .m-nav {
      display: flex;
      gap: 20px;
      align-items: center;
      .tnav-link,
      .tnav-link-notif {
        .nav-img,
        .logout-img {
          width: 35px;
        }
      }
    }

    @media screen and (min-width: 1161px) {
      display: none;
    }
  }
  .left {
    width: 320px;
    padding: 2rem 2rem 4rem 2rem;
  }
  .right {
    width: calc(100vw - 320px);
  }

  @media screen and (max-width: 1160px) {
    flex-direction: column-reverse;
    .left {
      position: fixed;
      top: 0;
      width: 100vw;
      // border: 1px solid #f2f2f2;
      z-index: 20;
      background: rgba(0, 0, 0, 0.75);
      min-height: 100vh;
      height: -webkit-fill-available;
      height: -moz-available;
      transition: all 0.3s linear;
      display: block;
      left: ${({ show }: AuthWrapperProps) => (show ? "0" : "-100vw")};
      border: none;
      overflow-y: auto;
      .l-nav {
        width: 350px;
        border-radius: 20px;
      }
    }
    .right {
      width: 100vw;
      min-height: 100vh;
      margin-top: 80px;
    }
  }

  @media screen and (max-width: 500px) {
    .left {
      display: none;
    }
    .right {
      width: 100vw;
      min-height: 100vh;
    }
  }
`;

export default AuthIndex;
