import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useGetIndSavingsAllQuery } from "../../redux/services/saving-service";
import ReactApexChart from "react-apexcharts";

const Piechart = () => {
  const { data, refetch }: any = useGetIndSavingsAllQuery();
  const [totalAmount, setTotalAmount] = useState<number>(0);
  let series: number[] = [];
  let labels: string[] = [];

  data?.forEach((data: any) => {
    labels.push(data?.name);
    series.push(Number(data?.amount));
  });

  useEffect(() => {
    if (series.length > 0) {
      let reducedAmt = series?.reduce((curr, next) => {
        return curr + next;
      }, 0);
      setTotalAmount(reducedAmt);
    }
  }, [series]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const options: any = {
    chart: {
      height: "200px",
      type: "donut",
      redrawOnParentResize: true,
    },
    colors: ["#059157", "#51468E", "#A78E5D", "#626C79", "#3B374D"],
    plotOptions: {
      pie: {
        donut: {
          size: "60%",
          labels: {
            show: false,
            total: {
              show: true,
              label: "",
              formatter: () => `₦${totalAmount.toFixed(2).toLocaleString()}`,
            },
          },
        },
        startAngle: -90,
        endAngle: 270,
        height: "200px",
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
    },
    legend: {
      show: "false",
      fonSize: "8px",
      position: "right",
      offsetY: 10,
      height: 300,
      width: 140,
      fontFamily: "Helvetica, Arial",
      fontWeight: 500,
      horizontalAlign: "left",

      markers: {
        width: 6,
        height: 6,
        strokeWidth: 0,
        strokeColor: "#fff",
        fillColors: undefined,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
    },
    labels: labels,
    responsive: [],
  };

  return (
    <Wrapper>
      <div className="chart-h">
        <p className="m-0">Individual Plans</p>
      </div>
      {totalAmount === 0 ? (
        <p>Nothing to show here</p>
      ) : (
        <>
          <ReactApexChart
            options={options}
            type="donut"
            series={series}
            height={200}
            width={400}
          />
          {/* <div className="total">
            <p>Total</p>
            <p className="total_amount">
              ₦{totalAmount.toFixed(2).toLocaleString()}
            </p>
          </div> */}
        </>
      )}
    </Wrapper>
  );
};

export default Piechart;

const Wrapper = styled.div`
  height: 280px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  position: relative;

  .total {
    position: absolute;
    text-align: center;
    left: 9.5rem;
    top: 8.4rem;
    color: #000;
    font-weight: 600;
    font-size: 15px;

    .total_amount {
      margin: 0 !important;
      margin-top: -10px !important;
    }
  }

  @media only screen and (max-width: 450px) {
    .total {
      position: absolute;
      text-align: center;
      left: 25%;
      top: 8.4rem;
      color: #000;
      font-weight: 500;
      font-size: 13px;

      .total_amount {
        margin: 0 !important;
        margin-top: -10px !important;
      }
    }
  }
`;
