import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ChangeCardState {
  cardSelected: boolean;
  userCards: any[];
  selectedCardId: string;
  personalFrequencyId: string;
}
const initialState: ChangeCardState = {
  cardSelected: false,
  userCards: [],
  selectedCardId: "",
  personalFrequencyId: "",
};

export const cardSelectedSlice = createSlice({
  name: "changeCard",
  initialState,

  reducers: {
    changeCardSelected: (state, action: PayloadAction<boolean>) => {
      state.cardSelected = action.payload;
      return state;
    },
    updateUserCards: (state, action: PayloadAction<any>) => {
      state.userCards = action.payload;
      return state;
    },
    updateSelectedCardId: (state, action: PayloadAction<string>) => {
      state.selectedCardId = action.payload;
      return state;
    },
    updateSelectedFreqCardId: (state, action: PayloadAction<string>) => {
      state.personalFrequencyId = action.payload;
      return state;
    },
  },
});

export const cardChangeActions = cardSelectedSlice.actions;

export default cardSelectedSlice.reducer;
