//import {gsap} from "gsap";
//import { ScrollTrigger } from "gsap/ScrollTrigger";
import {
  RefObject,
  useState,
  useEffect /*  , useRef, */,
  // useRef,
} from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import planIcon from "../assets/icons/plansIcon.svg";
import targetIcon from "../assets/icons/targetIcon.svg";
import cooperativeIcon from "../assets/icons/cooperativeIcon.svg";
import { BsArrowRight } from "react-icons/bs";
import { ReactComponent as ArrowRight } from "../assets/icons/right-arrow.svg";
import {
  cooperativeGroupSavings,
  individualSavings,
  targetGroupSavings,
  user,
} from "../utils/routes";

import {
  Heading2,
  Heading4,
  Heading5Light,
  Paragraph,
  Heading5,
  MyParagraph,
} from "./bits/Text";

function SavingsTypes({ productRef }: { productRef: RefObject<HTMLElement> }) {
  const [isCardHover, setIsCardHover] = useState<boolean>(false);
  const [cardNum, setCardNum] = useState<number>(0);

  useEffect(() => {}, [isCardHover]);
  //gsap.registerPlugin(ScrollTrigger)

  /*  let titleHeading = useRef<null>(null)
  let other = useRef<null>(null)
  
  let saving1 = useRef<null>(null)
  let saving2 = useRef<null>(null)
  let saving3 = useRef<null>(null)
 */
  /*   useEffect(() => {

    gsap.from( titleHeading , {
      y: "50%",
      ease: "expo",
      opacity:0,
      duration: 1,
      scrollTrigger:{
        trigger: productRef.current,
        start: "top center",
      }
    })
    gsap.from( other , {
      y: "50%",
      ease: "expo",
      opacity:0,
      duration: 1,
      scrollTrigger:{
        trigger: productRef.current,
        start: "top center",
      }
    })

    gsap.from( saving1 , {
      y: "50%",
      ease: "expo",
      opacity:0,
      delay: 0.4,
      duration: 1,
      scrollTrigger:{
        trigger: productRef.current,
        start: "top center",
      }
    })
    gsap.from( saving2 , {
      y: "50%",
      ease: "expo",
      opacity:0,
      delay: 0.5,
      duration: 1,
      scrollTrigger:{
        trigger: productRef.current,
        start: "top center",
      }
    })
    gsap.from( saving3 , {
      y: "50%",
      ease: "expo",
      opacity:0,
      delay: 0.6,
      duration: 1,
      scrollTrigger:{
        trigger: productRef.current,
        start: "top center",
      }
    })
    
  }, [productRef]) */

  // const [cardHeight, setCardHeight] = useState<number>(0);

  // const coopRef = useRef<null | HTMLElement>(null);
  return (
    <SavingsTypes.Wrapper ref={productRef}>
      <div className="products">
        <Heading2 className="text-center" mb="10px !important" fontSize="30px">
          Our Products
        </Heading2>
        <MyParagraph
          className="text-center"
          fontSize="14px !important"
          mb="0 !important"
          mt="0 !important"
        >
          Achieve a steady and healthy financial habits by using any of our
          available plan types
        </MyParagraph>
        <div className="cards">
          <div
            className="card"
            onMouseEnter={() => {
              setIsCardHover(true);
              setCardNum(1);
            }}
            onMouseLeave={() => {
              setIsCardHover(false);
              setCardNum(0);
            }}
          >
            <h5>Individual Plans</h5>
            <img
              src={planIcon}
              alt=""
              style={{ marginTop: "1.5rem", width: "40px" }}
            />
            <p>
              Plan towards your short and long term goals such as (trips, new
              gadgets, house rent, retirement pan and lots more) and watch your
              money grow
            </p>
            <Link
              to={`${user}/${individualSavings}`}
              style={
                isCardHover && cardNum === 1
                  ? { color: "#fff" }
                  : { color: "#059157" }
              }
            >
              Piggy Bank{" "}
              <BsArrowRight
                style={
                  isCardHover && cardNum === 1
                    ? { color: "#fff" }
                    : { color: "#059157" }
                }
              />
            </Link>
          </div>

          <div
            className="card"
            onMouseEnter={() => {
              setIsCardHover(true);
              setCardNum(2);
            }}
            onMouseLeave={() => {
              setIsCardHover(false);
              setCardNum(0);
            }}
          >
            <h5>Target Group Plans</h5>
            <img
              src={targetIcon}
              alt=""
              style={{ marginTop: "1.5rem", width: "50px" }}
            />
            <p>
              Making contributions in groups can be exciting and motivating as
              you plan towards a set goal with friends and family
            </p>
            <Link
              to={`${user}/${targetGroupSavings}`}
              style={
                isCardHover && cardNum === 2
                  ? { color: "#fff" }
                  : { color: "#059157" }
              }
            >
              Target Savings{" "}
              <BsArrowRight
                style={
                  isCardHover && cardNum === 2
                    ? { color: "#fff" }
                    : { color: "#059157" }
                }
              />
            </Link>
          </div>

          <div
            className="card"
            onMouseEnter={() => {
              setIsCardHover(true);
              setCardNum(3);
            }}
            onMouseLeave={() => {
              setIsCardHover(false);
              setCardNum(0);
            }}
          >
            <h5>Cooperative Group Contributions (Adashi/Esusu/Akawo)</h5>
            <img
              src={cooperativeIcon}
              alt=""
              style={{ marginTop: "1.5rem", width: "40px" }}
            />

            <p>
              With cooperative group contributions, you can take part in group
              contributions with rotatory payout order easily and also monitor
              the group progress
            </p>
            <Link
              to={`${user}/${cooperativeGroupSavings}`}
              style={
                isCardHover && cardNum === 3
                  ? { color: "#fff" }
                  : { color: "#059157" }
              }
            >
              Group Savings{" "}
              <BsArrowRight
                style={
                  isCardHover && cardNum === 3
                    ? { color: "#fff" }
                    : { color: "#059157" }
                }
              />
            </Link>
          </div>
        </div>
      </div>
    </SavingsTypes.Wrapper>
  );
}

SavingsTypes.Wrapper = styled.div`
  padding-top: 7rem;
  padding-bottom: 7rem;
  p,
  h2,
  h4,
  h5 {
    margin: 0;
  }

  p {
    margin: 40px 0;
    opacity: 0.7;
  }

  .products {
    max-width: 1500px;
    width: 90%;
    margin: 0 auto;
  }
  .cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    height: 40vh;
    width: 97%;
    margin: 0 auto;
  }
  .card {
    padding: 50px 20px;
    border: none;
    height: 350px;
    width: 95%;
    border-radius: 10px;
    border: 1px solid #f0f0f0;
    color: #33277b;
    /* box-shadow: -1px 3px 46px -9px rgba(0, 0, 0, 0.13); */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    transition: 0.3s ease-in;

    &:hover {
      background: #08cb7b;
      color: #fff !important;
    }

    h5 {
      font-size: 16px;
      font-weight: bold;
      color: inherit;
    }
    p {
      margin: 20px 0;
      opacity: 1;
      color: inherit;
    }
    a {
      font-size: 16px;
      font-weight: bold;
    }
  }
  a {
    font-weight: bold;
  }
  @media screen and (max-width: 1180px) {
    .cards {
      height: auto;
    }
    .cards {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

export default SavingsTypes;
