import { useEffect, useLayoutEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";

import logoutImg from "../assets/icons/logout_red.svg";
import notifImg from "../assets/icons/d-nav-notif.svg";
import { ReactComponent as NairaIcon } from "../assets/icons/naira-white.svg";
import profImg from "../assets/img/image 1.png";
import { logoutUser, userData } from "../helpers/authHelper";
import { indChartData, tarGrpChartData } from "../helpers/chartData";
import { formatNumber } from "../helpers/formatNumbers";
import { useShow, useShowNav } from "../pages/authPages/AuthIndex";
import { useGetUserProfileQuery } from "../redux/services/auth-services";
import {
  useGetCoopGroupInvitesQuery,
  useGetIndSavingsAllQuery,
  useGetTarGroupInvitesQuery,
  useGetTarGroupJoinedQuery,
} from "../redux/services/saving-service";
import { ReactComponent as AmountBg } from "../assets/img/amountBg.svg";
import AmountBg2 from "../assets/img/Balance.png";
import withdrawBg from "../assets/img/withdraw.svg";
import individualBg from "../assets/img/individual.svg";
import targetBg from "../assets/img/target.svg";
import cooperativeBg from "../assets/img/coorperatuveBg.svg";
import { updateStatus } from "../redux/slices/amountDisplay-slice";
import { updateNo } from "../redux/slices/notification-slice";
import { updateBVNStatus } from "../redux/slices/requestBVN-slice";
import { RootState, useAppDispatch, useAppSelector } from "../redux/store";
import { settings, transactions } from "../utils/routes";
import AddBank from "./AddBank";
import DCardRow1 from "./DCardRow1";
import DCardRow2 from "./DCardRow2";
import FormSubmit from "./FormSubmit";
import TransactionTable2 from "./TransactionTable2";
import { Burger } from "./UnAuthNavbar";
import Warning from "./Warning";
import AdCarousel from "./bits/AdCarousel";
import AmountDisplay from "./bits/AmountDisplay";
import LoadingRoller from "./bits/LoadingRoller";
import ModalA from "./bits/ModalA";
import cashIcon from "../assets/icons/cash.svg";
import walletIcon from "../assets/icons/wallet.svg";

const DashboardLanding = () => {
  const { show: open, setShow: setOpen } = useShow();
  const { setShowNav } = useShowNav();
  // const [stashBal, setStashBal] = useState(0);
  const [logoutOpen, setLogoutOpen] = useState<boolean>(false);
  const [currentModal, setCurrentModal] = useState<number>(0);

  const dispatch: any = useAppDispatch();

  const amountDisplayState = useAppSelector(
    (state: RootState) => state.amountDisplay
  );

  const requestBVNState = useAppSelector(
    (state: RootState) => state.requestBVN
  );

  // const { open, setOpen, setShowNav } = props;
  const {
    data: userData1,
    isLoading: userData1Loading,
    refetch: userRefetch,
  }: any = useGetUserProfileQuery();
  const {
    data: indData,
    isLoading: indDataLoading,
    refetch: indRefetch,
  }: any = useGetIndSavingsAllQuery();
  const {
    data: coopData,
    isLoading: coopDataLoading,
    refetch: coopRefetch,
  }: any = useGetCoopGroupInvitesQuery();
  const {
    data: tarData,
    isLoading: tarDataLoading,
    refetch: tarRefetch,
  }: any = useGetTarGroupInvitesQuery();
  const {
    data: joinedGroups,
    isLoading: joinedGroupsLoading,
    refetch: refetchJoined,
  }: any = useGetTarGroupJoinedQuery();

  // component will mount, then return for when the component is unmounting
  useLayoutEffect(() => {
    setShowNav(false);
    //set it to false because we already have a horizontal navbar on dashboard landing page
    return () => {
      setShowNav(true);
    };
  }, [setShowNav]);

  useEffect(() => {
    if (
      !requestBVNState.bvnViewed &&
      userData1?.user_profile?.bvn_verified === 0
    ) {
      setCurrentModal(1);
    }
  }, [requestBVNState?.bvnViewed, userData1?.user_profile?.bvn_verified]);

  // useEffect(() => {
  //   setStashBal(parseFloat(userData1?.wallet.balance));
  // }, [userData1]);

  const notifData = coopData && tarData && [...coopData, ...tarData];
  const notifNo: number = notifData?.length;

  useEffect(() => {
    indRefetch();
    coopRefetch();
    tarRefetch();
    refetchJoined();
    userRefetch();
    dispatch(updateNo(notifNo));
  }, [
    indRefetch,
    coopRefetch,
    tarRefetch,
    refetchJoined,
    userRefetch,
    notifNo,
    dispatch,
  ]);
  let indChartAmt: number[] = [];
  let tarGrpChartAmt: number[] = [];

  indData &&
    indData.length > 0 &&
    (indChartAmt = indChartData(indData).finalAmt);

  joinedGroups &&
    joinedGroups.length > 0 &&
    (tarGrpChartAmt = tarGrpChartData(joinedGroups)?.finalAmt);

  // for individual savings amount
  const indAmt =
    indChartAmt.length > 0
      ? indChartAmt.reduce((prev: number, curr: number) => prev + curr)
      : 0;
  const indAmtNaira = indAmt
    ? formatNumber(parseInt(indAmt.toFixed(2).split(".")[0]))
    : 0;
  const indAmtKobo = indAmt ? indAmt.toFixed(2).split(".")[1] : "00";

  // for target group savings amount
  const tarGrpAmt =
    tarGrpChartAmt?.length > 0
      ? tarGrpChartAmt?.reduce((prev: number, curr: number) => prev + curr)
      : 0;
  const tarGrpAmtNaira = tarGrpAmt
    ? formatNumber(parseInt(tarGrpAmt?.toFixed(2)?.split(".")[0]))
    : 0;
  const tarGrpAmtKobo = tarGrpAmt ? tarGrpAmt?.toFixed(2)?.split(".")[1] : "00";

  // for stash balance
  const stashBal = parseFloat(userData1?.wallet.balance);
  const stashBalNaira = stashBal
    ? formatNumber(parseInt(stashBal?.toFixed(2)?.split(".")[0]))
    : 0;
  const stashBalKobo = stashBal ? stashBal?.toFixed(2)?.split(".")[1] : "00";

  // for total savings amount
  const totalAmt = indAmt + stashBal + tarGrpAmt;
  const totalAmtNaira = totalAmt
    ? formatNumber(parseInt(totalAmt?.toFixed(2)?.split(".")[0]))
    : 0;
  const totalAmtKobo = totalAmt ? totalAmt?.toFixed(2)?.split(".")[1] : "00";

  const hideAmount = () => {
    dispatch(updateStatus());
  };

  return (
    <>
      <DashboardLanding.Wrapper>
        <div className="container">
          <div className="d-header">
            <div className="burger">
              <Burger open={open} setOpen={setOpen} />
            </div>
            <span className="d-text">Welcome, {userData()?.name}</span>
            <div className="d-nav">
              <NavLink to={settings} className="tnav-link">
                {userData1 ? (
                  <img
                    src={userData1?.user_profile?.avatar}
                    alt="user display icon"
                    className="nav-img"
                    style={{
                      borderRadius: "100%",
                    }}
                  />
                ) : (
                  <img
                    src={profImg}
                    alt="user display icon"
                    className="nav-img"
                    style={{
                      borderRadius: "100%",
                    }}
                  />
                )}
              </NavLink>

              <NavLink to="settings?tab=4" className="tnav-link-notif">
                <img
                  src={notifImg}
                  className="nav-img"
                  alt="notifications icon"
                />
                {notifNo > 0 && <span className="notif-no">{notifNo}</span>}
              </NavLink>

              <NavLink
                to="#"
                onClick={() => setLogoutOpen(true)}
                className="tnav-link"
              >
                <img src={logoutImg} className="logout-img" alt="logout icon" />
                {/* <p className="mb-0">Logout</p> */}
              </NavLink>
            </div>
          </div>
          {userData1Loading ||
          indDataLoading ||
          coopDataLoading ||
          tarDataLoading ||
          joinedGroupsLoading ? (
            <LoadingRoller />
          ) : (
            <>
              <span className="m-text">Welcome, {userData()?.name}</span>
              <div className="mt-4-container">
                <div className="m4-content1">
                  <img src={AmountBg2} alt="" />
                  <div className="carouselCont">
                    <AdCarousel sliderColour="#059157">
                      <div className="mt-4-content">
                        <p>Total Balance</p>
                        <h2 className="my-0 savings">
                          <NairaIcon className="savingsIcon" />
                          <AmountDisplay
                            text={`${totalAmtNaira}.${totalAmtKobo}`}
                            isAsterisk={amountDisplayState.asteriskStatus}
                          />
                          {amountDisplayState.asteriskStatus ? (
                            <i
                              onClick={() => hideAmount()}
                              style={{
                                opacity: 1,
                                fontSize: "18px",
                              }}
                              className={"far fa-eye-slash ast"}
                            ></i>
                          ) : (
                            <i
                              onClick={() => hideAmount()}
                              style={{ opacity: 1, fontSize: "18px" }}
                              className={"fas fa-eye-slash ast"}
                            ></i>
                          )}
                        </h2>
                      </div>
                      <div className="mt-4-content">
                        <p className="carousel_title">Individual Balance</p>
                        <h2 className="my-0 savings">
                          <NairaIcon className="savingsIcon" />
                          <AmountDisplay
                            text={`${indAmtNaira}.${indAmtKobo}`}
                            isAsterisk={amountDisplayState.asteriskStatus}
                          />
                          {amountDisplayState.asteriskStatus ? (
                            <i
                              onClick={() => hideAmount()}
                              style={{ opacity: 1, fontSize: "18px" }}
                              className={"far fa-eye-slash ast"}
                            ></i>
                          ) : (
                            <i
                              onClick={() => hideAmount()}
                              style={{ opacity: 1, fontSize: "18px" }}
                              className={"fas fa-eye-slash ast"}
                            ></i>
                          )}
                        </h2>
                      </div>
                      <div className="mt-4-content">
                        <p className="carousel_title">Target Group Balance</p>
                        <h2 className="my-0 savings">
                          <NairaIcon className="savingsIcon" />
                          <AmountDisplay
                            text={`${tarGrpAmtNaira}.${tarGrpAmtKobo}`}
                            isAsterisk={amountDisplayState.asteriskStatus}
                          />
                          {amountDisplayState.asteriskStatus ? (
                            <i
                              onClick={() => hideAmount()}
                              style={{ opacity: 1, fontSize: "18px" }}
                              className={"far fa-eye-slash ast"}
                            ></i>
                          ) : (
                            <i
                              onClick={() => hideAmount()}
                              style={{ opacity: 1, fontSize: "18px" }}
                              className={"fas fa-eye-slash ast"}
                            ></i>
                          )}
                        </h2>
                      </div>
                      <div className="mt-4-content">
                        <p className="carousel_title">My Purse</p>
                        <h2 className="my-0 savings">
                          <NairaIcon className="savingsIcon" />
                          <AmountDisplay
                            text={`${stashBalNaira}.${stashBalKobo}`}
                            isAsterisk={amountDisplayState.asteriskStatus}
                          />

                          {amountDisplayState.asteriskStatus ? (
                            <i
                              onClick={() => hideAmount()}
                              style={{ opacity: 1, fontSize: "18px" }}
                              className={"far fa-eye-slash ast"}
                            ></i>
                          ) : (
                            <i
                              onClick={() => hideAmount()}
                              style={{ opacity: 1, fontSize: "18px" }}
                              className={"fas fa-eye-slash ast"}
                            ></i>
                          )}
                        </h2>
                      </div>
                    </AdCarousel>
                  </div>
                </div>
                <div className="m4-content2">
                  <Link to="/user/stash">
                    <div className="withdrawWrapper">
                      <div className="background">
                        <img src={withdrawBg} alt="" />
                        <div className="x_mt_content">
                          <img src={cashIcon} alt="" />
                          <p>My Purse</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link to="/user/individual-savings">
                    <div className="individualWrapper">
                      <div className="background">
                        <img src={individualBg} alt="" />
                        <div className="x_mt_content">
                          <img src={walletIcon} alt="" />
                          <p>Individual</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link to="/user/target-group-savings">
                    <div className="targetWrapper">
                      <div className="background">
                        <img src={targetBg} alt="" />
                        <div className="x_mt_content">
                          <img src={walletIcon} alt="" />
                          <p>Target</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link to="/user/cooperative-group-savings">
                    <div className="cooperativeWrapper">
                      <div className="background">
                        <img src={cooperativeBg} alt="" />
                        <div className="x_mt_content">
                          <img src={walletIcon} alt="" />
                          <p>Cooperative</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              {/* <DCardRow1
                stashBalNaira={stashBalNaira}
                stashBalKobo={stashBalKobo}
              /> */}
              <p className="desc-text" style={{ fontWeight: "400" }}>
                Add money to see your savings grow! Get started{" "}
              </p>
              <DCardRow2 />
              <div className="trans-text">
                <p className="desc-text desc-text2">Recent Transactions</p>
                <Link
                  to={transactions}
                  style={{ textDecoration: "none", color: "#059157" }}
                >
                  See All
                </Link>
              </div>
              <div className="trans">
                <TransactionTable2 />
              </div>
            </>
          )}
        </div>
      </DashboardLanding.Wrapper>
      <ModalA isShown={logoutOpen} hide={() => {}}>
        <Warning
          warningTitle={"Confirm Logout"}
          warningText={<p>Are you sure you want to logout?</p>}
          closeModal={() => setLogoutOpen(false)}
          confirm={() => logoutUser()}
          confirmText={"OK"}
        />
      </ModalA>
      {currentModal === 1 && (
        <ModalA isShown={true} hide={() => {}}>
          <Warning
            warningTitle={"Add your BVN"}
            warningText={
              <p>
                You need to add your Bank Verification Number (BVN) to perform
                any transaction. Please click <b>Proceed</b> to do this
              </p>
            }
            closeModal={() => {
              setCurrentModal(0);
              dispatch(updateBVNStatus(true));
            }}
            confirm={() => {
              setCurrentModal(2);
              dispatch(updateBVNStatus(true));
            }}
            confirmText={"Proceed"}
          />
        </ModalA>
      )}
      {currentModal === 2 && (
        <ModalA isShown={true} hide={() => {}}>
          <AddBank func={setCurrentModal} num={3} />
        </ModalA>
      )}
      {currentModal === 3 && (
        <ModalA isShown={true} hide={() => {}}>
          <FormSubmit onConfirm={() => setCurrentModal(0)} />
        </ModalA>
      )}
    </>
  );
};

DashboardLanding.Wrapper = styled.div`
  .d-header {
    color: #33277b;
    display: flex !important;
    justify-content: space-between;
    padding: 2rem 0;
    flex-direction: row;
    align-items: center;
    //   margin-top: 2rem;
    .d-nav {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      min-width: 25%;
      align-items: center;
      padding-right: 25px;
      .nav-img {
        height: 40px;
        width: 40px;
      }
      .logout-img {
        height: 30px;
        width: 30px;
      }
      .nav-sub {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
      .tnav-link {
        margin-left: 2rem;
        text-decoration: none;
        color: #33277b;
      }
      .tnav-link-notif {
        margin-left: 2rem;
        text-decoration: none;
        color: #33277b;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        position: relative;

        .notif-no {
          background-color: #33277b;
          border-radius: 100%;
          padding: 0.2rem 0.5rem;
          color: white;
          cursor: pointer;
          position: absolute;
          z-index: 10;
          right: -10px;
          top: -13px;
          font-size: 12px;
        }
      }
    }
    .d-text {
      margin-top: 1rem;
      font-weight: 500;
      font-size: 24px;
      // color: #33277b;
      text-transform: capitalize;
    }
  }

  .container {
    color: #33277b;
    .mt-4-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 3.5rem;
      padding-right: 15px;
      margin-bottom: 3rem;
      /* border: 3px solid; */

      .m4-content1 {
        /* height: 200px; */
        position: relative;
        flex: 0.42;
        img {
          height: 175px;
          width: 100%;
        }
        .carouselCont {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
        }
        .mt-4-content {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          color: #fff !important;
        }
      }
      .m4-content2 {
        display: flex;
        align-items: center;
        flex: 0.58;
        justify-content: space-between;

        .withdrawWrapper,
        .individualWrapper,
        .targetWrapper,
        .cooperativeWrapper {
          height: 175px;
          .background {
            width: 120px;
            height: 175px;
            border-radius: 17px;
            overflow: hidden;
            position: relative;
            transition: all 0.3s ease-in;

            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
            .x_mt_content {
              position: absolute;
              color: #fff;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: 0.5rem;
              p {
                font-size: 14px;
              }
              img {
                width: 30px;
                height: 30px;
              }
            }

            :hover {
              border: 4px solid #5f5f5f6;
              box-shadow: 0px 0px 12px 6px rgba(69, 69, 69, 0.28);
              -webkit-box-shadow: 0px 0px 12px 6px rgba(69, 69, 69, 0.28);
              -moz-box-shadow: 0px 0px 12px 6px rgba(69, 69, 69, 0.28);
            }
          }
        }
      }
    }

    .m-text {
      margin-top: 1rem;
      font-weight: 600;
      font-size: 25px;
      // color: #33277b;
      text-transform: capitalize;
    }

    .dCarou {
      display: flex;
      flex-direction: column;
    }

    .carou {
      li button {
        background: #059157;
      }

      li button::selection {
        background: #059157;
      }
    }

    .trans-text {
      display: flex;
      flex-direction: row;
      padding-right: 25px;
      justify-content: space-between;
      .desc-text {
        font-weight: 400 !important;
      }
    }

    .savings {
      display: flex;
      flex-direction: row;
      align-items: center;
      .savingsIcon {
        margin-inline-end: 0.25rem;
        margin-bottom: 0.2rem;
      }
      .ast {
        color: #fff;
        margin-inline-start: 1rem;
      }
    }
  }

  @media screen and (min-width: 1161px) {
    .burger {
      display: none;
    }
    .m-text {
      display: none;
    }
  }
  @media screen and (max-width: 1160px) {
    .d-text {
      display: none;
    }
  }
  @media screen and (max-width: 991px) {
    .d-header {
      padding-left: 1rem;
    }
    .m-text {
      padding-left: 1rem;
    }
    .desc-text {
      padding-left: 1rem;
      /* margin-top: 300px; */
    }
    .mt-4-container {
      flex-direction: row;
    }
    .container {
      padding: 0 4rem !important;
      display: unset;
    }
  }
  @media screen and (max-width: 920px) {
    .container {
      .mt-4-container {
        /* margin-left: 1rem; */
        padding-right: 15px;
        /* height: 155px; */

        .m4-content1 {
          position: relative;
          margin-top: 0 !important;
          flex: 0.5;
          height: 155px;
          img {
            height: 100%;
            width: 100%;
          }
          .carouselCont {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          .mt-4-content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: #fff !important;
          }
        }
        .m4-content2 {
          flex: 0.5;
          height: 155px;
          gap: 1rem;

          .withdrawWrapper,
          .individualWrapper,
          .targetWrapper,
          .cooperativeWrapper {
            .background {
              width: 100px;
              height: 155px;
              border-radius: 17px;
              overflow: hidden;
              position: relative;
              transition: all 0.3s ease-in;

              img {
                object-fit: cover;
                width: 100%;
                height: 100%;
              }
              .x_mt_content {
                position: absolute;
                color: #fff;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 0.5rem;
                p {
                  font-size: 14px;
                }
                img {
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .mt-4-container {
      flex-direction: column;
      gap: 1.5rem;
      align-items: unset;
      justify-content: unset;
      padding: 10px;

      .m4-content1 {
        flex: 1;
        width: 100%;
        .img {
          width: 100%;
        }
      }
      .m4-content2 {
        flex: 1;
        width: 100%;
      }
    }
    .desc-text {
      margin-top: 280px;
    }
  }
  @media screen and (max-width: 753px) {
    .container {
      padding-right: 8px;
      .mt-4-container {
        flex-direction: column;
        gap: 1.5rem;
        align-items: center;
        justify-content: center;
        padding: 10px;

        /* min-height: 155px; */
        /* height: 300px; */

        .m4-content1 {
          flex: 1;
          width: 100%;
          .img {
            width: 100%;
          }
        }
        .m4-content2 {
          flex: 1;
          width: 100%;
        }
      }
    }

    .desc-text {
      margin-top: 80px;
    }
    .desc-text2 {
      margin-top: 0;
    }
  }
  @media screen and (max-width: 500px) {
    .mt-4-container {
      padding-right: 0;
      padding: 10px;
    }
    .m4-content2 {
      max-width: 100vw;
      overflow-x: hidden;
    }
    .d-header {
      justify-content: space-between;

      .d-text {
        display: none;
      }
    }
    .trans {
      max-width: 100vw;
      overflow-x: scroll;
    }
    .tnav-link {
      img {
        width: 10px;
      }
    }
  }
  @media screen and (max-width: 458px) {
    .m-text {
      font-size: 17px !important;
      margin-bottom: 10px !important;
    }
    .container {
      /* padding-right: 8px; */
      .mt-4-container {
        flex-direction: column;
        gap: 1.5rem;
        align-items: center;
        justify-content: center;
        padding-right: 0;
        padding: 10px;

        /* min-height: 155px; */
        /* height: 300px; */

        .m4-content1 {
          flex: 1;
          /* width: 420px; */
          .img {
            /* width: 410px; */
          }
        }
        .m4-content2 {
          flex: 1;
          width: 420px;
          max-width: 98%;
          min-height: 175px;
          overflow-x: scroll;

          .withdrawWrapper,
          .individualWrapper,
          .targetWrapper,
          .cooperativeWrapper {
            .background {
              width: 100px;
              height: 175px;
              border-radius: 17px;
              overflow: hidden;
              position: relative;
              transition: all 0.3s ease-in;

              img {
                object-fit: cover;
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 420px) {
    .mt-4-container {
      padding-right: 0;
      padding: 10px;
      .m4-content1 {
        width: 100vw;
        img {
          /* width: 380px !important; */
        }
      }
      ..m4-content2 {
        .withdrawWrapper,
        .individualWrapper,
        .targetWrapper,
        .cooperativeWrapper {
          .background {
            width: 100px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 375px) {
    .mt-4-container {
      padding-right: 0;
      padding: 10px;
      .m4-content1 {
        width: 100vw;
        img {
          width: 350px !important;
        }
      }
    }
  }
`;

export default DashboardLanding;
