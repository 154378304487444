import { createSlice } from "@reduxjs/toolkit";

export interface ADState {
  asteriskStatus: boolean;
  currentFormData: {};
}

const initialState: ADState = {
  asteriskStatus: false,
  currentFormData: {},
};

export const amountDisplaySlice = createSlice({
  name: "amountDisplay",
  initialState,

  reducers: {
    updateStatus: (state) => {
      state.asteriskStatus = !state.asteriskStatus;
    },
    updateFormData: (state, action) => {
      state.currentFormData = action.payload;
    },
  },
});

export const { updateStatus, updateFormData } = amountDisplaySlice.actions;

export default amountDisplaySlice.reducer;
