import React, { useEffect, useState, useRef } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  savings,
  settings,
  transactions,
  user,
  withdrawal,
} from "../utils/routes";
import logo from "../assets/icons/logo2 1.svg";
import { ReactComponent as DashboardIcon } from "../assets/icons/dashboard.svg";
import { ReactComponent as LogoutIcon } from "../assets/icons/logout.svg";
import { ReactComponent as MyAcctIcon } from "../assets/icons/my-acct.svg";
import navgen from "../assets/icons/nav-gen.svg";
import { ReactComponent as SavingsIcon } from "../assets/icons/savings.svg";
import { ReactComponent as TransactionsIcon } from "../assets/icons/transactions.svg";
import { ReactComponent as WithdrawalIcon } from "../assets/icons/withdrawal.svg";
import ModalA from "./bits/ModalA";
import Warning from "./Warning";
import { logoutUser } from "../helpers/authHelper";
import gsap from "gsap";

type NavProps = {
  isShow: boolean;
  setIsShow: any;
};

const MNavbar = ({ isShow, setIsShow }: NavProps) => {
  //   let el = gsap();
  const portalDiv = document.getElementById("mobileNav")!;

  let navRef = useRef();

  useEffect(() => {
    if (isShow) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = null!;
      document.body.style.position = null!;
    }
    return () => {
      document.body.style.overflow = null!;
      document.body.style.position = null!;
    };
  }, [isShow]);

  useEffect(() => {
    gsap.to(navRef, { duration: 0.4, x: 0 });
  }, []);

  const closeNav = () => {
    gsap.to(navRef, { duration: 0.3, x: -280 });
    setTimeout(() => {
      setIsShow(false);
    }, 200);
  };
  const router = useLocation();

  const [logoutOpen, setLogoutOpen] = useState<boolean>(false);

  return createPortal(
    <ModalWrapper onClick={closeNav}>
      <NavWrapper
        onClick={(e: any) => {
          e.stopPropagation();
        }}
        ref={(div: any) => (navRef = div)}
      >
        <div className="mNav">
          <div className="container">
            <Link to={user} className="logoWrapper" onClick={closeNav}>
              <img src={logo} alt="Adashi-Logo" width="80px" height="78px" />
            </Link>
            <ul>
              <li className="nav-item">
                <NavLink
                  to={user}
                  className={`nav-link ${
                    router.pathname === "/user" ? "activ" : ""
                  }`}
                  onClick={closeNav}
                >
                  <DashboardIcon className="nav-svg" />
                  <span className="ms-3">Dashboard</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={savings}
                  className={`nav-link ${
                    router.pathname === "/user/savings" ? "activ" : ""
                  }`}
                  onClick={closeNav}
                >
                  <SavingsIcon className="nav-svg" />
                  <span className="ms-3">Plans</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={transactions}
                  className={`nav-link ${
                    router.pathname === "/user/transactions" ? "activ" : ""
                  }`}
                  onClick={closeNav}
                >
                  <TransactionsIcon className="nav-svg" />
                  <span className="ms-3">Transactions</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={withdrawal}
                  className={`nav-link ${
                    router.pathname === "/user/withdrawal" ? "activ" : ""
                  }`}
                  onClick={closeNav}
                >
                  <WithdrawalIcon className="nav-svg" />
                  <span className="ms-3">Withdrawal</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={settings}
                  className={`nav-link ${
                    router.pathname === "/user/settings" ? "activ" : ""
                  }`}
                  onClick={closeNav}
                >
                  <MyAcctIcon className="nav-svg" />
                  <span className="ms-3">My Account</span>
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink
                  to="#"
                  className="nav-link"
                  onClick={() => {
                    setLogoutOpen(true);
                  }}
                >
                  <LogoutIcon className="nav-svg" />
                  <span className="ms-3">Log out</span>
                </NavLink>
              </li> */}
            </ul>
            <img
              src={navgen}
              alt="GenerateMonthly"
              width={"200px"}
              height="161.63px"
            />
          </div>{" "}
        </div>
        <ModalA isShown={logoutOpen} hide={() => {}}>
          <Warning
            warningTitle={"Confirm Logout"}
            warningText={<p>Are you sure you want to logout?</p>}
            closeModal={() => setLogoutOpen(false)}
            confirm={() => logoutUser()}
            confirmText={"OK"}
          />
        </ModalA>
      </NavWrapper>
    </ModalWrapper>,
    portalDiv
  );
};

export default MNavbar;

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  padding: 2rem 0;
  /* display: none; */
  /*align-items: center;
  justify-content: center; */
`;

const NavWrapper = styled.div`
  background: #e6f4ee;
  width: 280px;
  border-top-right-radius: 30px;
  min-height: 100%;
  /* max-height: 100%; */
  overflow-y: scroll;
  border-bottom-right-radius: 30px;
  padding: 2rem 1.4rem;
  transform: translateX(-280px);

  .mNav {
    height: 100%;

    .container {
      .logoWrapper {
        display: flex;
        justify-content: center;
        img {
          width: 60px;
        }
      }
      ul {
        list-style-type: none;
        margin-top: 2rem;
        padding: 0px;
      }

      .nav-item {
        display: flex;

        .nav-link {
          width: 190px;
          color: #33277b;
          padding: 0.5rem 1rem 0.5rem 1rem;
          margin: 1rem 1rem 0.4rem;
          border-radius: 8px;
          font-style: 15px;
          display: flex;
          align-items: center;
        }
        .nav-svg {
          fill: #33277b;
          width: 20px;
        }

        .activ {
          color: #fff;
          background: #33277bcc;
          // padding: 0.8rem 2.6rem;
          .nav-svg {
            fill: #fff;
          }
        }

        .nav-link:hover,
        .nav-link:focus,
        .nav-link:active {
          color: #fff;
          background: #33277bcc;
          .nav-svg {
            fill: #fff;
          }
        }
      }
    }
  }
`;
