//import {gsap} from 'gsap'
import { RefObject, useEffect /* , useRef */, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import next from "../assets/icons/Vector.svg";
import ellipse from "../assets/img/Ellipse 1096.svg";
import heroIcon1 from "../assets/img/heroIcon1.svg";
import heroIcon2 from "../assets/img/heroIcon2.svg";
import heroIcon3 from "../assets/img/heroIcon3.svg";
import heroIcon4 from "../assets/img/heroIcon4.svg";
import heroImg1 from "../assets/img/heroImg1.png";
import heroImg2 from "../assets/img/heroImg2.png";
import heroImg3 from "../assets/img/heroImg3.png";
import bgBanner from "../assets/img/eclispeBg.png";
import line from "../assets/img/line.png";
import Iphone from "../assets/img/phone.png";

import { Heading2, Paragraph, MyParagraph } from "./bits/Text";

function Hero({ heroRef }: { heroRef: RefObject<HTMLElement> }) {
  //let headingRef = useRef(null)
  //let headingRef2 = useRef(null)
  //let pTextRef = useRef(null)
  //let pTextRef2 = useRef(null)
  //let buttonRef = useRef(null)
  //let miscRef = useRef(null)
  //let heroImg = useRef(null)
  //let bannerRef = useRef(null)

  /*   useEffect(() => {
    gsap.from(headingRef, {
      opacity: 0,
      duration: 1,
      delay: .6,
      translateY: "100%",
      ease: "expo",
    })
    gsap.from(headingRef2, {
      opacity: 0,
      duration: 1,
      delay: .6,
      translateY: "100%",
      ease: "expo",
    })
    gsap.from(pTextRef, {
      opacity: 0,
      duration: 1,
      delay: .6,
      translateY: "100%",
      ease: "expo",
    })
    gsap.from(pTextRef2, {
      opacity: 0,
      duration: 1,
      delay: .6,
      translateY: "100%",
      ease: "expo",
    })
    gsap.from(buttonRef, {
      opacity: 0,
      duration: 1,
      delay: .6,
      translateY: "100%",
      ease: "expo",
    })
    gsap.from(miscRef, {
      opacity: 0,
      duration: 1,
      delay: .6,
      translateY: "100%",
      ease: "expo",
    })
    gsap.from(heroImg, {
      opacity: 0,
      duration: 1,
      delay: .6,
      translateY: "100%",
      ease: "expo",
    })
    gsap.from(bannerRef, {
      opacity: 0,
      delay: .2,
      duration: 1,
    })
  }, []) */
  const [count, setCount] = useState<number>(1);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCount((prevCount) => (prevCount === 3 ? 1 : prevCount + 1));
    }, 4000);

    return () => clearTimeout(timer);
  }, [count]);

  const Span = styled.span`
    font-family: "karla", sans-serif !important;
    background: ${count % 2 === 0 ? "rgb(255, 164, 18)" : "rgb(5, 145, 87)"};
    background: ${count % 2 === 0
      ? "-moz-linear-gradient(90deg,#ffa412 0%,#059157 100%)"
      : "-moz-linear-gradient(90deg,#059157 0%,#ffa412 100%)"};
    background: ${count % 2 === 0
      ? "-webkit-linear-gradient(90deg,#ffa412 0%,#059157 100%)"
      : "-webkit-linear-gradient(90deg,#059157 0%,#ffa412 100%)"};
    background: ${count % 2 === 0
      ? "linear-gradient(90deg,#ffa412 0%,#059157 100%)"
      : "linear-gradient(90deg,#059157 0%,#ffa412 100%)"};

    filter: ${count % 2 === 0
      ? 'progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffa412",endColorstr="#059157",GradientType=1)'
      : 'progid:DXImageTransform.Microsoft.gradient(startColorstr="#059157",endColorstr="#ffa412",GradientType=1)'};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    transition: all 0.3s ease-in-out;
  `;

  return (
    <Hero.Wrapper ref={heroRef}>
      <div className="hero">
        <div className="hero_text">
          <Heading2 className="mt-4 heading_text" fontSize="48px !important">
            Revolutionizing The Age Long <br />
            <Span>Adashi/Esusu/Akawo </Span> <br />
            <Span> Traditional Contribution</Span>
          </Heading2>
          <Heading2 className="mt-4 heading_text2" fontSize="12px !important">
            Revolutionizing the age long Adashi/Esusu/Akawo traditional
            contribution
          </Heading2>
          <MyParagraph
            color="#47486B"
            className="p_text"
            fontSize="15px"
            style={{ lineHeight: "1.5" }}
          >
            Adashi helps you create halal contributions towards achieving a{" "}
            better financial <br />
            future while also providing a platform where rotatory contribution
            is <br /> possible in groups.
          </MyParagraph>
          <Paragraph color="#47486B" className="p_text2">
            Adashi helps you create halal contributions towards achieving a
            better financial future while also providing a platform where
            rotatory contribution is possible in groups.
          </Paragraph>
          {/* <Link to="/signup" className="get_started">
            Get Started
            <span>
              <img src={next} alt="next" />
            </span>
          </Link> */}

          <div className="hero_misc">
            <div className="hero_misc_item">
              <img src={heroIcon1} alt="" />
              <span>Rent</span>
            </div>
            <div className="hero_misc_item">
              <img src={heroIcon2} alt="" />
              <span>Vacation</span>
            </div>
            <div className="hero_misc_item">
              <img src={heroIcon3} alt="" />
              <span>Thrift</span>
            </div>
            <div className="hero_misc_item">
              <img src={heroIcon4} alt="" />
              <span>Fees</span>
            </div>
          </div>
        </div>

        <div className="hero_img">
          {/* <img
            className="bg_banner"
            style={{
              position: "absolute",
              top: "130px",
              right: 0,
              pointerEvents: "none",
            }}
            src={bgBanner}
            alt=""
          /> */}
          <span
            className="bg_banner_img"
            style={{
              position: "absolute",
              top: "160px",
              right: "30px",
              pointerEvents: "none",
            }}
          >
            {count === 1 && <img src={heroImg1} alt="" />}
            {count === 2 && <img src={heroImg2} alt="" />}
            {count === 3 && <img src={heroImg3} alt="" />}
          </span>
          {/* <img
            className="phone"
            style={{
              position: "relative",
              zIndex: "1000",
              width: "90%",
              pointerEvents: "none",
            }}
            src={Iphone}
            alt=""
          /> */}
        </div>
      </div>

      {/* <img
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          transform: "translateX(-30%) translateY(30%) rotate(180deg)",
        }}
        src={ellipse}
        alt="app"
      /> */}
    </Hero.Wrapper>
  );
}

Hero.Wrapper = styled.div`
  padding-top: 130px;
  width: 100%;
  position: relative;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
  p {
    margin: 30px 0;
    line-height: 36px;
    font-weight: 100;
  }

  * {
    margin: 0;
  }

  .hero {
    display: flex;
    align-items: center;
    max-width: 1500px;
    width: 90%;
    margin: 0 auto;
  }

  .heading_text {
    line-height: 56px;
    font-weight: 800;
    margin-bottom: 20px;
    font-size: 36px;
  }

  .get_started {
    display: block;
    width: max-content;
    background: #059157;
    border-radius: 8px;
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    padding: 13px 25px;
    text-decoration: none;
  }

  .get_started span {
    margin-left: 14px;

    img {
      width: 20px;
    }
  }

  @media only screen and (max-width: 1050px) {
    .hero_text {
      width: 50%;
      position: relative;
      z-index: 10;
    }
  }
  .hero_img {
    img {
      width: 690px;
      height: 560px;
    }
    @media only screen and (max-width: 1050px) {
      display: none;
    }
  }
  .heading_text2,
  .p_text2 {
    display: none;
  }

  .hero_misc {
    margin-top: 60px;
    display: flex;
    align-items: center;
    pointer-events: none;
    width: 100%;
  }

  .hero_misc_item {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    height: 150px;
  }

  .hero_misc_item:first-child {
    margin-left: 0;
  }

  .hero_misc_item svg {
    margin-right: 20px;
  }

  .hero_misc_item span {
    color: #33277b;
  }
  .bg_banner {
    width: 47rem;
    height: 55rem;
  }
  .bg_banner_img {
    @media screen and (max-width: 1278px) {
      display: none;
    }
    img {
      width: 45rem;
      height: 34rem;

      @media screen and (max-width: 1384px) {
        width: 41rem;
        height: 31rem;
      }
      @media screen and (max-width: 1321px) {
        width: 38rem;
        height: 28rem;
      }
      @media screen and (max-width: 1278px) {
        width: 33rem;
        height: 23rem;
      }
    }
  }
  @media screen and (max-width: 1280px) {
    .hero_misc {
      display: none;
    }
    .bg_banner {
      display: none;
    }
    .hero {
      flex-direction: column;
    }
    .hero_text {
      width: 100%;
    }
    .hero_img {
      width: 0;
    }
    .phone {
      display: none;
    }
  }

  @media screen and (max-width: 500px) {
    .heading_text {
      font-size: 24px;
    }
    .heading_text,
    .p_text {
      display: block;
    }

    .heading_text {
      font-size: 35px;

      span {
        font-size: 35px;
      }
    }
    .p_text {
      display: none;
      font-size: 14px;
    }
    .p_text2 {
      display: block;
      line-height: 1.5;
      font-size: 14px;
    }
    /* .heading_text2,
    .p_text2 {
      display: block;
    } */
  }
`;

export default Hero;
